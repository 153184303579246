<script setup lang="ts">
import {RouterLink, RouterView} from 'vue-router'
import HelloWorld from '@/components/HelloWorld.vue'
import {ref, watch} from "vue";

defineProps<{}>()

const PROTOCOL = "https";
const PORT = 443;
// const SERVER_URI = "10.10.10.103";
const SERVER_URI = "100.92.65.116";
const SERVER_URI_PORT = `${SERVER_URI}:${PORT}`;
const farmComponent = {
  waterRetention: "waterRetention",
  waterLevelReader: "waterLevelReader",
};
const waterRetention = ref<WaterRetention>({
  active: false,
  farmComponent: farmComponent.waterRetention,
});

const checkboxChanged = async () => {
  console.log("checkboxChanged", waterRetention.value);
  const response = await fetch(`${PROTOCOL}://${SERVER_URI_PORT}/activate/${farmComponent.waterRetention}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({active: waterRetention.value.active}),
  });
  const data = await response.json();
  console.log("waterRetentions activated", data);
}

const wellLevel = ref<number>();
const wellError = ref<Error>();
const fetchWell = async () => {
  try {
    const response = await fetch(`${PROTOCOL}://${SERVER_URI_PORT}/well`)
    const data = await response.json()
    wellLevel.value = data.level.meters;
  } catch (err: unknown) {
    if (err instanceof Error) {
      wellError.value = err
    }
  }
}
fetchWell();
</script>

<template>
  <header>
    <img alt="Vue logo" class="logo" src="@/assets/logo.svg" width="125" height="125"/>

    <div class="wrapper">
      <HelloWorld msg="Závlaha - Farma Mezi řádky"/>
      <p>Vrt hladina: {{ wellLevel }}m</p>
      <br/>
      <p v-if="wellError" style="border: 2pt solid red;">
        Vrt chyba: {{ wellError.message }}
      </p>
      <nav>
        <!--        <RouterLink to="/">Home</RouterLink>-->
        <!--        <RouterLink to="/about">About</RouterLink>-->
      </nav>

    </div>
  </header>
  <span>
    Dopouštění vody {{waterRetention.active}}
  <label class="switch">
    <input type="checkbox" v-model="waterRetention.active" @change="() => checkboxChanged()">
    <span class="slider round"></span>
  </label>

  </span>
  <RouterView/>
</template>

<style scoped>
header {
  line-height: 1.5;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}

/* Button switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
